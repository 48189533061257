import React from 'react'
import styles from '../CSS/Footer.module.css'
import { FaLinkedin } from "react-icons/fa6";
import { FaGithub } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { IoMdCall } from "react-icons/io";




const Footer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.t1}>contact me</div>
      <div className={styles.t2}><IoIosMail style={{fontSize:"1.5rem"}}/> jayanthjayram6968@gmail.com</div>
      <div className={styles.t2}><IoMdCall style={{fontSize:"1.5rem"}}/> +91 9743783119</div>
      <a href='/Jayanth_Mern_Resume.pdf' download='Jayanth_Mern_Resume.pdf' style={{textDecoration:"none", color:"white"}}><div className={styles.btn}>Download Resume</div></a>
      <div className={styles.wrapper}>
        <a href="https://www.linkedin.com/in/jayanth-p-8b86ab236/" target="_blank" rel="noopener noreferrer"><FaLinkedin  className={styles.icon}/></a>
        <a href="https://github.com/Jayanth9743" target="_blank" rel="noopener noreferrer"><FaGithub className={styles.icon} /></a>
      </div>
    </div>
  )
}

export default Footer
