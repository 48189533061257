import React from 'react'
import styles from '../CSS/About.module.css'
import img from '../photos/1.jpg'

const About = () => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper1}>
        <img src={img} alt="" />
      </div>
      <div className={styles.wrapper2}>
        <div className={styles.title}>Front-End Developer</div>
        <div className={styles.name}>JAYANTH P</div>
        <div className={styles.details}>"Dedicated to developing user-centric web experiences with a commitment to clean code and modern design. Eager to infuse creativity and technical expertise into innovative projects."</div>
      </div>
    </div>
  )
}

export default About