import React from 'react'
import { Link, animateScroll as scroll  } from 'react-scroll'
import styles from '../CSS/Nav.module.css'
import { RxHamburgerMenu } from "react-icons/rx";
import  { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';



const Navbar = () => {
  
const [togglemenu, setTogglemneu] = useState(false)
  const handleToggle = () => {
      setTogglemneu(!togglemenu)
  }

  return (
    <>
    <nav className={styles.container}>
     <Link onClick={() => scroll.scrollToTop()} className={styles.logo}>Jayanth P</Link>
     <ul className={styles.wrapper}>
        <li><Link to="about" spy={true} smooth={true} duration={800} >About</Link></li>
        <li><Link to="skills" spy={true} smooth={true} duration={800} >Skills</Link></li>
        <li><Link to="projects" spy={true} smooth={true} duration={800} >Projects</Link></li>
        <li><a href='/Jayanth_Mern_Resume.pdf' download='Jayanth_Mern_Resume.pdf'  className={styles.link}>resume</a></li>
        <li> <Link to="footer" spy={true} smooth={true} duration={800}>Contacts</Link></li>
      </ul>
     <div className={styles.ham}>
          <RxHamburgerMenu style={{fontSize:"1.5rem"}} onClick={handleToggle}/>
    </div>
    </nav>
    <nav className={styles.container1} style={togglemenu?{transform:"translateY(0)"}:{transform:"translateY(-100%)"}}>
        <CloseIcon style={{fontSize:"1.5rem", marginLeft:"80%", cursor:"pointer"}} onClick={handleToggle}/>
        <li><Link to="about" spy={true} smooth={true} duration={800} className={styles.list} onClick={handleToggle}>About</Link></li>
        <hr />
        <li><Link to="skills" spy={true} smooth={true} duration={800} className={styles.list} onClick={handleToggle}>Skills</Link></li>
        <hr />
        <li><Link to="projects" spy={true} smooth={true} duration={800} className={styles.list} onClick={handleToggle}>Projects</Link></li>
        <hr />
        <li><a href='/Jayanth_Mern_Resume.pdf' download='Jayanth_Mern_Resume.pdf'  className={styles.link} onClick={handleToggle}>resume</a></li>
        <hr />
        <li> <Link to="footer" spy={true} smooth={true} duration={800} className={styles.list} onClick={handleToggle}>Contacts</Link></li>
    </nav>
    </>
  )
}

export default Navbar