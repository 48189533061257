import React from 'react'
import styles from '../CSS/Skills.module.css'
import { FaReact } from "react-icons/fa";
import { FaHtml5 } from "react-icons/fa";
import { SiRedux } from "react-icons/si";
import { SiMui } from "react-icons/si";
import { FaBootstrap } from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io5";
import { FaCss3 } from "react-icons/fa";
import { FaGitAlt } from "react-icons/fa";
import { RiTailwindCssFill } from "react-icons/ri";
import { SiMongodb } from "react-icons/si";
import { SiExpress } from "react-icons/si";
import { IoLogoNodejs } from "react-icons/io5";
import { FaJava } from "react-icons/fa6";

const Skills = () => {
  return (
    <div className={styles.container}>
        <div className={styles.wrapper}>
            <div className={styles.name}>
                <p>My Skills</p>
            </div>
            <div className={styles.skills}>
                <div className={styles.card}>
                    <SiMongodb className={styles.icons}/>
                    <p>MongoDB</p>
                </div>
                <div className={styles.card}>
                    <SiExpress className={styles.icons}/>
                    <p>Express</p>
                </div>
                <div className={styles.card}>
                    <FaReact className={styles.icons}/>
                    <p>ReactJS</p>
                </div>
                <div className={styles.card}>
                    <IoLogoNodejs className={styles.icons}/>
                    <p>Node JS</p>
                </div>
                <div className={styles.card}>
                    <IoLogoJavascript className={styles.icons}/>
                    <p>Javascript</p>
                </div>
                <div className={styles.card}>
                    <FaJava className={styles.icons}/>
                    <p>Java</p>
                </div>
                <div className={styles.card}>
                    <SiRedux className={styles.icons}/>
                    <p>Redux</p>
                </div>
                <div className={styles.card}>
                    <RiTailwindCssFill className={styles.icons}/>
                    <p>Tailwind CSS</p>
                </div>
                <div className={styles.card}>
                    <SiMui className={styles.icons}/>
                    <p>Material UI</p>
                </div>
                <div className={styles.card}>
                    <FaBootstrap className={styles.icons}/>
                    <p>Bootstrap</p>
                </div>
                <div className={styles.card}>
                    <FaGitAlt className={styles.icons}/>
                    <p>Git</p>
                </div>
                <div className={styles.card}>
                    <FaCss3 className={styles.icons}/>
                    <p>CSS</p>
                </div>
                <div className={styles.card}>
                    <FaHtml5 className={styles.icons}/>
                    <p>HTML</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Skills