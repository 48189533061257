import React from 'react'
import styles from '../CSS/Project.module.css'
import pw from '../photos/pw.png'
import pe from '../photos/pe.png'
import pc from '../photos/pc.png'
import pg from '../photos/pg.png'

const Projects = () => {
  return (
    <div className={styles.container}>
        <p className={styles.contName}>Projects</p>
        <div className={styles.wrapper}>
        <div className={styles.card}>
            <img src={pg} alt="" />
            <div className={styles.title}>Grocery app</div>
            <div className={styles.details}>Built using MERN stack, this platform offers a seamless shopping experience with dynamic cart and wishlist features.</div>
            <div className={styles.btn}>
              <a href='https://github.com/Jayanth9743/grocery-app' target="_blank" rel="noopener noreferrer" className={styles.b1}>code</a>
              <a href='https://grocery-app9743.netlify.app' target="_blank" rel="noopener noreferrer" className={styles.b2}>view</a>
            </div>
          </div>
          <div className={styles.card}>
            <img src={pe} alt="" />
            <div className={styles.title}>Vastra</div>
            <div className={styles.details}>A web platform designed to offer users a smooth and enjoyable online shopping journey.</div>
            <div className={styles.btn}>
              <a href='https://github.com/Jayanth9743/Vastra' target="_blank" rel="noopener noreferrer" className={styles.b1}>code</a>
              <a href='https://chic-cocada-8c9a65.netlify.app' target="_blank" rel="noopener noreferrer" className={styles.b2}>view</a>
            </div>
          </div>
          <div className={styles.card}>
            <img src={pc} alt="" />
            <div className={styles.title}>Gyana AI</div>
            <div className={styles.details}>An AI-powered web platform designed to offer users advanced content generation capabilities, similar to Google's Gemini.</div>
            <div className={styles.btn}>
              <a href='https://github.com/Jayanth9743/Gyana-AI' target="_blank" rel="noopener noreferrer" className={styles.b1}>code</a>
              <a href='https://sparkly-gelato-46dfca.netlify.app' target="_blank" rel="noopener noreferrer" className={styles.b2}>view</a>
            </div>
          </div>
          <div className={styles.card}>
            <img src={pw} alt="" />
            <div className={styles.title}>Weather app</div>
            <div className={styles.details}>An weather application designed to provide users with real-time temperature updates for any location worldwide.</div>
            <div className={styles.btn}>
              <a href='https://github.com/Jayanth9743/weatherApp' target="_blank" rel="noopener noreferrer" className={styles.b1}>code</a>
              <a href='https://whimsical-klepon-9b1d31.netlify.app' target="_blank" rel="noopener noreferrer" className={styles.b2}>view</a>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Projects
